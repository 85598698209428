<div id="soft_token_request" class="portal-content-screen">
  <h1>Request Soft Token</h1>

  <form [formGroup]="requestForm" class="pt-3">

    <div formGroupName="user">
      <div class="form-group">
        <label for="sid" class="tuxedo_label required">Standard ID (SID)</label>
        <input type="text" class="form-control" id="sid" formControlName="sid" required aria-required="true" maxlength="100">
        <div *ngIf="sid.touched && sid.errors?.required" class="validation-state error">
          Standard ID (SID) is required
        </div>
      </div>
      <div class="form-group">
        <label for="password" class="tuxedo_label required">Password</label>
        <input type="password" class="form-control" id="password" formControlName="password"
               required
               aria-required="true">
        <small class="form-text">
          <a href="https://password.jpmchase.com" target="Managed-Password-Portal">Forgot your password?</a>
        </small>
        <div *ngIf="password.touched && password.errors?.required" class="validation-state error">
          Password is required
        </div>
      </div>
      <div class="form-group">
        <label for="domain" class="tuxedo_label required">Domain</label>
        <div class="select_wrapper">
          <select class="form-control" placeholder="Select Token Type" id="domain"
                  formControlName="domain" required
                  aria-required="true">
            <option value="">Select Domain</option>
            <option *ngFor="let domain of DOMAINS" value="{{domain}}">{{domain}}</option>
          </select>
          <i class="caret_container" aria-hidden="true"></i>
        </div>
        <div *ngIf="domain.touched && domain.errors?.required" class="validation-state error">
          Domain is required
        </div>
      </div>

      <div class="form-group">
        <label for="voiceAccessCode" class="tuxedo_label required">Primary Access Code</label>
        <small class="form-text" id="voiceAccessCodeHelperText">
          Enter code provided to you verbally by a trusted contact (manager or peer)
        </small>
        <input type="password" class="form-control" id="voiceAccessCode"  formControlName="voiceAccessCode"
               required
               aria-required="true"
               aria-describedby="voiceAccessCodeHelperText"
               inputmode="numeric">
        <div *ngIf="voiceAccessCode.touched && voiceAccessCode.errors?.required" class="validation-state error">
          One-time Access Code is required
        </div>
      </div>

      <div class="form-group">
        <label for="mobileAccessCode" class="tuxedo_label required">Secondary Access Code</label>
        <small class="form-text" id="mobileAccessCodeHelperText">
          Enter code provided to you via SMS (text), voice (call) or work email
        </small>
        <input type="password" class="form-control" id="mobileAccessCode"  formControlName="mobileAccessCode"
               required
               aria-required="true"
               aria-describedby="mobileAccessCodeHelperText"
               inputmode="numeric"
               data-toggle="tooltip"
               title="A mobile access code will be sent to your registered mobile device via SMS (text) after a verbal access code is generated by your manager"
               >
        <div *ngIf="mobileAccessCode.touched && mobileAccessCode.errors?.required" class="validation-state error">
          Authentication Code is required
        </div>
      </div>
    </div>

    <fieldset class="form-group">
      <legend class="tuxedo_label required">Select Token Type</legend>
      <small class="form-text" id="tokenLocationHelperText">
        Select the device you will be using the soft token on
      </small>
      <ul aria-describedby="tokenLocationHelperText" class="list_style">
        <li>
          <input class="tuxedo_radio_button" value="iphone" formControlName="deviceType" id="iphone" type="radio">
          <label for="iphone">iOS (Apple)</label>
        </li>
        <li>
          <input class="tuxedo_radio_button" formControlName="deviceType" value="android" id="android" type="radio">
          <label for="android">Android</label>
        </li>
<!--        <li role="listitem">-->
<!--          <input class="tuxedo_radio_button" value="desktop" formControlName="deviceType" id="desktop" type="radio">-->
<!--          <label for="desktop">Desktop</label>-->
<!--        </li>-->
      </ul>
      <div *ngIf="deviceType.touched && deviceType.errors?.required" class="validation-state error">
        Target Device is required
      </div>
    </fieldset>

    <div class="form-group">
      <label for="initialPin" class="tuxedo_label required">New PIN</label>
      <small class="form-text" id="initialPinHelperText">
        Your PIN must be {{MIN_PIN_DIGITS}}-{{MAX_PIN_DIGITS}} digits and cannot start with 0
      </small>
      <input type="password" class="form-control" id="initialPin" formControlName="initialPin" required aria-required="true"
             aria-describedby="initialPinHelperText" (input)="forceNumbersOnly($event)"
             minlength="{{MIN_PIN_DIGITS}}" maxlength="{{MAX_PIN_DIGITS}}" inputmode="numeric" appDigitOnly>
      <div *ngIf="initialPin.touched && initialPin.errors?.required" class="validation-state error">
        New PIN is required
      </div>
      <div *ngIf="initialPin.touched && initialPin.errors?.pattern" class="validation-state error">
        New PIN must not start with 0
      </div>
      <div *ngIf="initialPin.touched && initialPin.errors?.minlength" class="validation-state error">
        Minimum length is {{MIN_PIN_DIGITS}} digits
      </div>
      <div *ngIf="initialPin.touched && initialPin.errors?.maxlength" class="validation-state error">
        Maximum length is {{MAX_PIN_DIGITS}} digits
      </div>
    </div>
    <div class="form-group">
      <label for="confirmPin" class="tuxedo_label required">Confirm New PIN</label>
      <input type="password" class="form-control" id="confirmPin" formControlName="confirmPin" required
             aria-required="true" minlength="{{MIN_PIN_DIGITS}}" maxlength="{{MAX_PIN_DIGITS}}"
             inputmode="numeric" (input)="forceNumbersOnly($event)" appDigitOnly>

      <div *ngIf="confirmPin.touched && confirmPin.errors?.mismatchPassword" class="validation-state error">
        Passwords did not match
      </div>
      <div *ngIf="confirmPin.touched && confirmPin.errors?.required" class="validation-state error">
        Confirm New PIN is required
      </div>
    </div>
    <div style="margin-top: 50px;">
      <button type="button" class="btn btn-primary w-100"
              (click)="submitRequest($event)">Submit
      </button>
    </div>
  </form>
</div>
